@import 'src/styles/mixins';
@import 'src/styles/typography';

.root {
  @include centralize;
  background: var(--green-2);
  width: 100vw;
  height: 100dvh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 1;
}

.title {
  @extend %display1;
  font-size: 76px;
  font-weight: 700;
  color: var(--dark-bg-1);
  line-height: 88px;
}

.btn.btn {
  background: var(--dark-bg-1);
  color: var(--green-2);
}

.line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
}

.imgWrapper {
  object-fit: cover;
  max-height: 40dvh;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
